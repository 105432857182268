import React, {useState, useEffect, useRef, useCallback} from "react";
import { useParams } from "react-router";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";
import TabPanel from "../common/TabPanel";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ReactJson from "react-json-view";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {darkColor} from "../common/Theme";
import InvocationInsight from "./InvocationInsight";
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import LabelInvocation from "./LabelInvocation";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const maxWidth = 1024;
const maxHeight = 2048;

export default function AutomationInvocationPage() {
  let { automationId, invocationId } = useParams();
  const [automation, setAutomation] = useState(null);
  const [invocation, setInvocation] = useState(null);
  const [output, setOutput] = useState({});
  const [insights, setInsights] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inputFilePages, setInputFilePages] = useState();
  const [inputFilePageNum, setInputFilePageNum] = useState(0);
  const [tabIndex, setTabIndex] = React.useState(0);
  const pageCanvasRef = useRef(null);
  const overlayRef = useRef(null);
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState(maxWidth);
  const [containerHeight, setContainerHeight] = useState(maxHeight);

  useEffect(() => {
    setIsLoading(true);

    const fetchAutomation = async () => {
      try {
        const automationResponse = await axios({
          url: `https://${await guruUtils.apiDomain()}/automations/${automationId}`,
          headers: await guruUtils.getAuthHeaders(),
        });
        setAutomation(automationResponse.data);
      } catch (error) {
        console.error("Error fetching data", error);
        setError(error);
      }
    };

    const fetchInvocation = async () => {
      try {
        const invocationResponse = await axios({
          url: `https://${await guruUtils.apiDomain()}/automations/${automationId}/invocations/${invocationId}`,
          headers: await guruUtils.getAuthHeaders(),
        });
        setInvocation(invocationResponse.data);

        if (invocationResponse.data["output_url"]) {
          const outputResponse = await axios({
            url: invocationResponse.data["output_url"],
          });
          setOutput(outputResponse.data);
        }

        return invocationResponse.data;
      } catch (error) {
        console.error("Error fetching data", error);
        setError(error);
      }
    };

    const fetchInsights = async (invocationResult) => {
      try {
        const insightsResponse = await axios({
          url: `https://${await guruUtils.apiDomain()}/automations/${automationId}/invocations/${invocationId}/files/${invocationResult.files[0].file_id}/insights`,
          headers: await guruUtils.getAuthHeaders(),
        });
        setInsights(insightsResponse.data.sort((insight0, insight1) => {
          return insight0.reference.boundingBox.y < insight1.reference.boundingBox.y ? -1 : 1;
        }));
      } catch (error) {
        console.error("Error fetching data", error);
        setError(error);
      }
    };

    Promise.all([
      fetchAutomation(),
      fetchInvocation(),
    ]).then((results) => {
      const invocationResult = results[1];

      if (invocationResult && invocationResult.files.length > 0) {
        fetchInsights(invocationResult).then(() => setIsLoading(false));
      }
      else {
        setIsLoading(false);
      }
    });
  }, [automationId, invocationId]);

  const formatOutput = (output) => {
    var isValidJson = true;
    var result;
    if (typeof output === "object") {
      result = output;
    }
    else {
      try {
        result = JSON.parse(output);
      } catch (e) {
        isValidJson = false;
      }
    }

    if (isValidJson && typeof result === "object") {
      return (
        <ReactJson
          src={result}
          name={null}
          collapsed={2}
          sortKeys={false}
          displayDataTypes={false}
        />
      );
    }
    // TODO: should we use markdown preview for this, too?
    return <Typography className={"has-linebreaks"}>{output}</Typography>;
  };

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setInputFilePages(nextNumPages);
  }

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(Math.min(entry.contentRect.width, maxWidth));
      setContainerHeight(Math.min(entry.contentRect.height, maxHeight));
    }
  }, []);
  useResizeObserver(containerRef, {}, onResize);

  const rehypePlugins = [
    rehypeSanitize,
    // Remove the anchor links that are automatically added to the Markdown headings
    {
      rewrite: (node, index, parent) => {
        if (
          node.tagName === "a" &&
          parent &&
          /^h(1|2|3|4|5|6)/.test(parent.tagName)
        ) {
          parent.children = [parent.children[1]];
        }
      },
    },
  ];

  const updatePageNum = (newPageNum) => {
    newPageNum = Math.min(Math.max(0, newPageNum), inputFilePages - 1);
    setInputFilePageNum(newPageNum);
  };

  if (isLoading) {
    return <GuruConsolePageLayout p={2}>
      <CircularProgress/>
    </GuruConsolePageLayout>;
  }

  return <GuruConsolePageLayout>
    <Grid container>
      <Grid item xs={6} p={2} style={{maxHeight: '100vh', overflow: 'auto'}}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <ButtonGroup variant="outlined">
            <Button onClick={() => updatePageNum(inputFilePageNum - 1)}>{"<"}</Button>
            <Button>Page {inputFilePageNum + 1}</Button>
            <Button onClick={() => updatePageNum(inputFilePageNum + 1)}>{">"}</Button>
          </ButtonGroup>                           
        </Box>
        {
          automation.input_doc_types === [] &&
            <div style={{ height: "100%" }} data-color-mode="light">
              <MDEditor.Markdown
                source={invocation.input}
                style={{ whiteSpace: "pre-wrap" }}
                rehypePlugins={[rehypePlugins]}
              />
            </div>
        }
        {
          automation.input_doc_types.length > 0 && invocation.files[0].extension === "pdf" &&
            <Box ref={setContainerRef} style={{cursor: "crosshair"}}>
              <Document file={invocation.files[0].url} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={inputFilePageNum + 1} width={containerWidth} canvasRef={pageCanvasRef}>
                  <canvas ref={overlayRef}
                          width={pageCanvasRef.current ? pageCanvasRef.current.width : 1}
                          height={pageCanvasRef.current ? pageCanvasRef.current.height : 1}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 100,
                          }}/>
                </Page>
              </Document>
            </Box>
        }
      </Grid>
      <Grid item xs={6} p={2} style={{maxHeight: '100vh', overflow: 'auto'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
            <Tab style={{color: darkColor}} label="Insights"/>
            <Tab style={{color: darkColor}} label="Output"/>
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          {
            insights.filter((insight) => insight.reference.page === inputFilePageNum)
              .map((insight) => <Box key={`insight-${insight.id}`} pt={1}>
                <InvocationInsight insight={insight}
                                   pageCanvasRef={pageCanvasRef}
                                   overlayRef={overlayRef}/>
              </Box>)
          }
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {formatOutput(output)}
        </TabPanel>
      </Grid>
    </Grid>

    {overlayRef && <LabelInvocation canvasElement={overlayRef.current}/>}
  </GuruConsolePageLayout>;
};