import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

export default function InvocationInsight({
  insight,
  pageCanvasRef,
  overlayRef,
}) {
  const getBoxSize = () => {
    const rect = pageCanvasRef.current.getBoundingClientRect();
    return {
      width: rect.width,
      height: rect.height,
    };
  };

  const drawBoundingBox = (context, box) => {
    const { width, height } = getBoxSize();

    if (context) {
      context.clearRect(
        box.x * width,
        box.y * height,
        box.width * width,
        box.height * height
      );
    }
  };

  const prepareOverlayCanvas = () => {
    if (overlayRef.current) {
      const context = overlayRef.current.getContext("2d");
      const { width, height } = getBoxSize();
      context.fillStyle = "rgba(0, 0, 0, 0.75)";
      context.fillRect(0, 0, width, height);

      return context;
    }
  };

  const rowUnhovered = () => {
    if (overlayRef.current) {
      const context = overlayRef.current.getContext("2d");
      const { width, height } = getBoxSize();
      context.clearRect(0, 0, width, height);
    }
  };

  let cellStyle = {
    backgroundColor: "white",
    color: "black",
  };

  if (insight.type === "table") {
    const tableRowHovered = (row) => {
      const context = prepareOverlayCanvas();
      row.forEach((cell) =>
        drawBoundingBox(context, cell.reference.boundingBox)
      );
    };

    // Make sure these are sorted based on the y-coordinate
    const getMinY = (cells) => {
      return Math.min(...cells.map((cell) => cell.reference.boundingBox.y));
    };
    const sortedInsights = insight.content.sort(
      (a, b) => getMinY(a) - getMinY(b)
    );

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {sortedInsights.map((row, rowIndex) => (
              <TableRow
                key={`row-${rowIndex}`}
                rowSpan={row.rowSpan}
                onMouseEnter={() => tableRowHovered(row)}
                onMouseLeave={rowUnhovered}
              >
                {row.map((cell, cellIndex) => {
                  let style = { ...cellStyle };
                  if (
                    cell.types.some((type) => [
                      "TABLE_TITLE",
                      "TABLE_FOOTER",
                      "TABLE_SECTION_TITLE",
                      "TABLE_SUMMARY",
                    ])
                  ) {
                    style = {
                      backgroundColor: "#6131FF",
                      color: "white",
                      textAlign: "center",
                    };
                  }

                  return (
                    <TableCell
                      key={`cell-${cellIndex}`}
                      colSpan={cell.columnSpan}
                      style={style}
                    >
                      {cell.value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (insight.type === "form") {
    const formRowHovered = (field) => {
      const context = prepareOverlayCanvas();
      drawBoundingBox(context, field.reference.boundingBox);
    };

    // Make sure these are sorted based on the y-coordinate
    const sortedInsights = insight.content.sort((a, b) => {
      return a.reference.boundingBox.y - b.reference.boundingBox.y;
    });

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedInsights.map((field, index) => (
              <TableRow
                key={`${field}-${index}`}
                onMouseEnter={() => formRowHovered(field)}
                onMouseLeave={rowUnhovered}
              >
                <TableCell component="th" scope="row" style={cellStyle}>
                  <strong>{field.name}</strong>
                </TableCell>
                <TableCell style={cellStyle}>
                  {field.value?.toString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    const cellHovered = (insight, field) => {
      if (insight[field].reference.boundingBox) {
        const context = prepareOverlayCanvas();
        drawBoundingBox(context, insight[field].reference.boundingBox);
      }
    };

    const keys = Object.keys(insight.content[0]);
    // Make sure these are sorted based on the y-coordinate
    const getMinY = (fields) => {
      const boundingBox = fields[keys[0]].reference.boundingBox;
      return boundingBox ? boundingBox.y : Number.MAX_SAFE_INTEGER;
    };
    const sortedInsights = insight.content.sort(
      (a, b) => getMinY(a) - getMinY(b)
    );

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {keys.map((key, index) => {
                return (
                  <TableCell
                    key={`${key}-${index}`}
                    style={{ textTransform: "capitalize" }}
                  >
                    {key}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedInsights.map((insight, index) => (
              <TableRow key={`row-${index}`}>
                {keys.map((key, index) => {
                  return (
                    <TableCell
                      style={cellStyle}
                      key={`cell-${index}`}
                      onMouseEnter={() => cellHovered(insight, key)}
                      onMouseLeave={rowUnhovered}
                    >
                      {insight[key].value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
